<template>
  <div class="p-md-2 w-100">
    <div class="questionnaire-container py-3">
      <div class="questionBox">
        <div class="text-center mb-2">
          <h2 class="pb-1">Login securely to access your results and support tools</h2>
          <hr class="mhc-divider m-auto" />
        </div>

        <b-row class="my-2">
          <b-col md="5" sm="12">
            <h3 class="text-center">Login</h3>

            <div class="px-2">
              <!-- form -->
              <validation-observer ref="loginValidation">
                <b-form class="auth-login-form mt-2" @submit.prevent>
                  <!-- email -->
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false : null"
                        name="login-email"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between"></div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-row class="justify-content-center">
                    <p class="text-center mb-1">
                      <span>Forgot Password? </span>
                      <b-link
                        :to="{ name: 'forgot-password' }"
                        class="mhc-link"
                      >
                        <span>Reset Password</span>
                      </b-link>
                    </p>
                  </b-row>

                  <!-- submit buttons -->
                  <b-button
                    :disabled="btnDisabled"
                    type="submit"
                    variant="primary"
                    block
                    @click="validationForm"
                  >
                    LOGIN
                  </b-button>
                  <div
                    v-if="loading"
                    class="d-flex justify-content-center mt-2"
                  >
                    <b-spinner variant="primary" label="Loading..." />
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </b-col>

          <b-col md="2" sm="12" class="my-2 mt-md-0 text-center">
            <h3 style="color: #828b97">or</h3>
          </b-col>

          <b-col md="5" sm="12" class="text-center">
            <h3>Use Social Media</h3>
            <!-- if the user questionnaire slug is kept.. show social login links with questionnaire slug -->
            <b-form v-if="slugFlag == true" class="mt-2" @submit.prevent>
              <a
                :href="
                  'https://api.mentalhealthcheck.org/auth/form/facebook/' + slugString
                "
              >
                <div class="social-button">
                  <img src="/fb.png" />
                  <span class="ml-1">Continue with Facebook</span>
                </div>
              </a>

              <a
                :href="
                  'https://api.mentalhealthcheck.org/auth/form/google/' + slugString
                "
              >
                <div class="social-button mt-1">
                  <img src="/google.png" />
                  <span class="ml-1">Continue with Google</span>
                </div>
              </a>
            </b-form>

            <b-form v-else class="mt-2" @submit.prevent>
              <a href="https://api.mentalhealthcheck.org/auth/facebook">
                <div class="social-button">
                  <img src="/fb.png" />
                  <span class="ml-1">Continue with Facebook</span>
                </div>
              </a>

              <a href="https://api.mentalhealthcheck.org/auth/google">
                <div class="social-button mt-1">
                  <img src="/google.png" />
                  <span class="ml-1">Continue with Google</span>
                </div>
              </a>
            </b-form>
            <!-- https://mhc.codeforgery.xyz/auth/facebook -->
          </b-col>
        </b-row>

        <b-row class="justify-content-center">
          <p class=" d-flex flex-column d-sm-block text-center mb-1">
            <span>Don’t have an account? </span>
            <b-link :to="{ name: 'register' }" class="mhc-link">
              <span> Create your account here</span>
            </b-link>
          </p>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading: false,
      btnDisabled: false,
      slugFlag: false,
      slugString: "",
    };
  },
  mounted() {
    if (
      localStorage.getItem("questionnaireSlug") != null &&
      localStorage.getItem("questionnaireSlug") != ""
    ) {
      this.slugFlag = true;
      this.slugString = localStorage.getItem("questionnaireSlug");
    }

    //If Session Expired
    if (this.$route.query.sessionExp == 1) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Session Expired",
          icon: "AlertCircleIcon",
          variant: "info",
        },
      });
    }
  },
  computed: {
    userData(userData) {
      store.state.app.userData = userData;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    userData: {
      get() {
        return this.userData;
      },
      set(newName) {
        return newName;
      },
    },
  },
  methods: {
    updateUserData(userData) {
      store.commit("app/setUserData", userData);
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },
    login() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.btnDisabled = true;

          let questionnaireSlugQuery =
            localStorage.getItem("questionnaireSlug");
          let questionnaireSlug;

          if (questionnaireSlugQuery != "" && questionnaireSlugQuery != null) {
            questionnaireSlug = questionnaireSlugQuery;

            this.$http
              .post("/api/auth/login", {
                email: this.userEmail,
                password: this.password,
                questionnaire: questionnaireSlug,
              })
              .then((response) => {
                let userData = response.data;

                userData.avatar = this.makeAvatar(userData.username);

                this.updateUserData(userData);
                this.userData = userData;
                this.loading = false;
                this.btnDisabled = false;
              })
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Successful Login",
                    icon: "CheckSquareIcon",
                    variant: "success",
                  },
                });

                // Redirect to Home screen
                this.$router.push("/reminder");
              })
              .catch((error) => {
                this.loading = false;
                this.btnDisabled = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.message,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
                this.$refs.loginValidation.setErrors(error.response.data.error);
              });
          } else {
            this.$http
              .post("/api/auth/login", {
                email: this.userEmail,
                password: this.password,
              })
              .then((response) => {
                let userData = response.data;

                userData.avatar = this.makeAvatar(userData.username);

                this.updateUserData(userData);
                this.userData = userData;
                this.loading = false;
                this.btnDisabled = false;
              })
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Successful Login",
                    icon: "CheckSquareIcon",
                    variant: "success",
                  },
                });

                // Redirect to Form
                this.$router.push({ path: 'form' })
              })
              .catch((error) => {
                console.log(error);

                this.loading = false;
                this.btnDisabled = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.message,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
                this.$refs.loginValidation.setErrors(error.response.data.error);
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
